import { useForm } from 'react-hook-form';
import { Icons, Button } from '@zapfunnel/components';
import { Api } from '../../hooks/api';
import toast from 'react-hot-toast';
import { Container, ContentContainer, LoginForm } from './RecoveryAccess.style';
import { apiExtension } from '../../hooks/apiExtension';
import { useState } from 'react';

export function RecoveryAccess() {
  const [isLoadingCheckExtension, setIsLoadingCheckExtension] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<{ email: string }>();

  async function onRecoveryAccess({ email }: { email: string }) {
    const response = await Api.post<{ email: string }>('/access/recovery', {
      email,
      tenantId: process.env.REACT_APP_TENANT_ID
    });

    if ('email' in response) {
      toast.success(`Enviamos seu acesso para o email ${email}!`);
      return;
    }

    toast.error(`Não encontramos uma venda neste email!`);
  }

  async function handleVerifyExtension() {
    setIsLoadingCheckExtension(true);

    try {
      const responde = await apiExtension.get('/mutation:verifyExtension');

      if (responde.length > 1) {
        toast.error(`Multiplas extensoes intaladas!`);
        return;
      }

      if (responde.length === 0) {
        toast.success(`Voce ainda nao instalou nem uma extensao`);
        return;
      }

      console.log(responde);
    } finally {
      setIsLoadingCheckExtension(false);
    }
  }

  return (
    <>
      <Container>
        <ContentContainer>
          <LoginForm onSubmit={handleSubmit(onRecoveryAccess)}>
            <h4>Preencha seu e-mail abaixo e clique em solicitar para receber seu acesso!</h4>
            <input type="text" placeholder="Email" {...register('email', { required: true })} />

            <Button.Submit isLoading={isSubmitting} full={true} theme="dark">
              Solicitar
            </Button.Submit>
          </LoginForm>
          <h4>Verify Pong Extension</h4>
          <Button.Submit onClick={handleVerifyExtension} full isLoading={isLoadingCheckExtension}>
            Verificar Extensão.
          </Button.Submit>

          <h4 className="secondMessage">Clique no botão abaixo para baixar a extensão!</h4>
          <Button.Simple
            href="https://chromewebstore.google.com/detail/mentoria-patrick-voice/hdnnopdahbaelibebccnabojhmjmoefi"
            full={true}
            theme="dark"
            target="_blank">
            Baixar Extensão
          </Button.Simple>
          <footer>
            <div>
              <span className="loveMessage">
                <Icons.Love />
              </span>
            </div>
          </footer>
        </ContentContainer>
      </Container>
    </>
  );
}
