export class Api {
  static baseUrl = 'https://api.zapfunnel.com';
  static headers = {
    'Content-Type': 'application/json'
  };

  static async get<T = unknown>(url: string): Promise<T> {
    return fetch(Api.baseUrl + url, {
      headers: Api.headers
    }).then((response) => response.json());
  }

  static async post<T = unknown>(url: string, data?: unknown): Promise<T> {
    return fetch(Api.baseUrl + url, {
      method: 'POST',
      headers: Api.headers,
      body: JSON.stringify(data)
    }).then((response) => response.json());
  }

  static async put<T = unknown>(url: string, data?: unknown): Promise<T> {
    return fetch(Api.baseUrl + url, {
      method: 'PUT',
      headers: Api.headers,
      body: JSON.stringify(data)
    }).then((response) => response.json());
  }

  static async delete<T = unknown>(url: string): Promise<T> {
    return fetch(Api.baseUrl + url, {
      method: 'DELETE',
      headers: Api.headers
    }).then((response) => response.json());
  }

  static async patch<T = unknown>(url: string, data?: unknown): Promise<T> {
    return fetch(Api.baseUrl + url, {
      method: 'PATCH',
      headers: Api.headers,
      body: JSON.stringify(data)
    }).then((response) => response.json());
  }
}
