import { Icons, Button } from '@zapfunnel/components';
import { Container, ContentContainer } from './RecoveryAccess.style';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';

import { apiExtension } from '../../hooks/apiExtension';
import { Api } from '../../hooks/api';
import { AuthenticateInterface } from '../../types/SessionInterface';
import { LoadingPage } from '../../components/LoadingPage';

export function VerifyExtensionAccess() {
  const navigate = useNavigate();
  const { id: key } = useParams<{ id: string }>();

  const [extensionDashUrl, setExtensionDashUrl] = useState('');
  const [extensionId, setExtensionId] = useState('');
  const [needDownload, setNeedDownload] = useState(false);
  const [isLoadingNeedDownload, setIsLoadingNeedDownload] = useState(true);
  const [access, setAccess] = useState<{
    key: string;
    status: string;
    expirationDate: string;
    paidAt: string;
    tenant: {
      id: string;
      name: string;
      extensionId: string;
      logo: string;
    };
  }>();

  useEffect(() => {
    const accesses = JSON.parse(localStorage.getItem('@funils:access') || '[]') as {
      key: string;
      status: string;
      expirationDate: string;
      paidAt: string;
      tenant: {
        id: string;
        name: string;
        extensionId: string;
        logo: string;
      };
    }[];

    if (accesses.length === 0) {
      toast.error(`Não encontramos uma venda neste email!`);
      navigate('/');
      return;
    }

    const access = accesses.find((item) => item.key === key);
    setAccess(access);

    if (!access) {
      toast.error(`Não encontramos uma venda neste email!`);
      navigate('/');
      return;
    }

    setExtensionId(access.tenant.extensionId);
  }, []);

  useEffect(() => {
    if (!access) return;
    setTimeout(() => {
      handleVerifyExtension();
    }, 2000);
  }, [access]);

  async function handleVerifyExtension() {
    let extension: { id: string; dashboardUrl: string };

    try {
      const responde = await apiExtension.get<{ id: string; dashboardUrl: string }>(
        `/mutation:verifyExtension:${uuid()}`
      );

      if (responde.length > 1) {
        toast.error(`Multiplas extensoes intaladas!`);
        setNeedDownload(true);
        setIsLoadingNeedDownload(false);

        return;
      }

      if (responde.length === 0) {
        toast.success(`Voce ainda nao instalou nem uma extensao`);
        setNeedDownload(true);
        setIsLoadingNeedDownload(false);

        return;
      }
      [extension] = responde;
    } catch (error) {
      setNeedDownload(true);
      setIsLoadingNeedDownload(false);
      return;
    }

    const { session } = await Api.post<AuthenticateInterface>('/sessions', {
      key,
      tenantId: access?.tenant.id,
      firgerPrint: '76fa22cc-57d5-4d7d-bc57-94c25f07b0a6'
    });

    if (!session) {
      return toast.error('Chave inválida!');
    }

    console.log('oi', session);

    await apiExtension.delete(`db:principal:user`, session.user.id);

    console.log('oi 2', session);
    await apiExtension.post('db:principal:user', {
      id: session.user.id,
      email: session.user.email,
      name: session.user.name,
      phoneNumber: '',
      status: true
    });
    console.log('oi3', session);

    setExtensionDashUrl(extension.dashboardUrl);
  }

  async function openDashboardExtension() {
    await apiExtension.post('/mutation:openDashboard', {});
  }

  if (!extensionDashUrl && isLoadingNeedDownload) {
    return <LoadingPage />;
  }

  return (
    <>
      <Container>
        <ContentContainer>
          {needDownload && (
            <>
              <h4>Não encontramos a extensão baixada, poderia baixar?</h4>

              <Button.Simple
                href={`https://chromewebstore.google.com/detail/mentoria-patrick-voice/hdnnopdahbaelibebccnabojhmjmoefi?${extensionId}`}
                full={true}
                target="_blank"
                theme="dark">
                Baixar Extensão
              </Button.Simple>
            </>
          )}

          {extensionDashUrl && (
            <>
              <h4>Tudo ativado, já pode começar a usar sua nova extensão!</h4>
              <Button.Simple onClick={openDashboardExtension} full={true} theme="dark">
                Abrir Dashboard
              </Button.Simple>
            </>
          )}

          <footer>
            <div>
              <span className="loveMessage">
                <Icons.Love />
              </span>
            </div>
          </footer>
        </ContentContainer>
      </Container>
    </>
  );
}
