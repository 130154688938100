import { Loading } from '@zapfunnel/components';
import { LoadingPage as LoadingStyled } from './LoadingPage.style';

export function LoadingPage() {
  return (
    <LoadingStyled>
      <Loading theme="primary" size={15} />
    </LoadingStyled>
  );
}
