import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/browser';

import './index.scss';
import './colors.scss';

import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ClientContextProvider } from '@zapfunnel/components';
import { client } from './client';
import Access from './pages/access';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    Component: Access.Home
  },
  {
    path: '/access/verify-extension/:id',
    Component: Access.VerifyExtension
  },
  {
    path: '/recovery',
    Component: Access.RecoveryAccess
  }
]);

Sentry.init({ dsn: 'https://bf1e2f923a9746aa9b78d2521ddc3a67@tools.trace.mh4sh.dev/1' });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <ClientContextProvider client={client}>
    <Toaster position="top-right" />
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ClientContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
