import { HomeAccess } from './Home';
import { RecoveryAccess } from './RecoveryAccess';
import { VerifyExtensionAccess } from './VerifyExtensionAccess';

const Access = {
  Home: HomeAccess,
  RecoveryAccess: RecoveryAccess,
  VerifyExtension: VerifyExtensionAccess,
  ChooseExtension: VerifyExtensionAccess
};

export default Access;
