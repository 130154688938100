enum Methods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete'
}

export interface EventApiInterface {
  key: 'worker:api';
  data: {
    origin: 'access-page';
    route: string;
    method: Methods;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any;
  };
}

export interface EventApiResponseInterface {
  key: 'content-script:api';
  data: {
    route: string;
    method: Methods;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body: any;
  };
}

const apiStack = [] as {
  method: Methods;
  route: string;
  lastTimeout?: NodeJS.Timeout;
  addResponse: (response: unknown) => void;
  resolve: () => void;
}[];

document.addEventListener('worker:ZapStar<>whats', async (baseEvent) => {
  const event = baseEvent as CustomEvent<EventApiResponseInterface>;

  if (event.detail.key === 'content-script:api') {
    const item = apiStack.find(
      (apiItem) =>
        apiItem.route === event.detail.data.route && apiItem.method === event.detail.data.method
    );

    if (!item) {
      return;
    }

    item.addResponse(event.detail.data.body);

    if (item.lastTimeout) {
      clearTimeout(item.lastTimeout);
    }

    item.lastTimeout = setTimeout(() => {
      item.resolve();
      apiStack.splice(apiStack.indexOf(item), 1);
    }, 1000);
  }
});

export const apiExtension = {
  query: async <T>(method: Methods, route: string, body?: unknown): Promise<T[]> => {
    let timeoutId: NodeJS.Timeout | null = null;

    const response = await new Promise((resolve, reject) => {
      document.dispatchEvent(
        new CustomEvent<EventApiInterface>('whats<>ZapStar', {
          detail: {
            key: 'worker:api',
            data: {
              origin: 'access-page',
              method,
              route,
              ...(body !== undefined ? { body } : {})
            }
          }
        })
      );

      const responses = [] as unknown[];

      function addResponse(value: unknown) {
        responses.push(value);
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
        resolve(responses);
      }

      function resolvResponse() {
        resolve(responses);
      }

      apiStack.push({
        method,
        route,
        lastTimeout: undefined,
        resolve: resolvResponse,
        addResponse
      });

      timeoutId = setTimeout(() => {
        const item = apiStack.find((apiItem) => apiItem.route === route);
        if (item) {
          apiStack.splice(apiStack.indexOf(item), 1);
        }
        reject(new Error('Request timed out after 2 seconds'));
      }, 5000);
    });

    return response as T[];
  },
  get: async <T>(route: string, body?: unknown): Promise<T[]> => {
    return apiExtension.query<T>(Methods.GET, route, body);
  },
  post: async <T>(route: string, body: unknown): Promise<T[]> => {
    return apiExtension.query<T>(Methods.POST, route, body);
  },
  put: async <T>(route: string, body: unknown): Promise<T[]> => {
    return apiExtension.query<T>(Methods.PUT, route, body);
  },
  delete: async <T>(route: string, body: unknown): Promise<T[]> => {
    return apiExtension.query<T>(Methods.DELETE, route, body);
  }
};
