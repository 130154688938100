import { HomeVersion, Client } from '@zapfunnel/components';

export const client: Client = {
  name: 'PS Voice',
  frase: 'O rei do whatsapp!',
  favicon: '',
  logo: 'https://i.imgur.com/Fsl5c0i.png',
  features: {
    illustration: {
      available: true
    },
    helpButton: {
      link: 'https://drive.google.com/drive/folders/10-oa7TdKis993u8jDOVIkR2PTICJBY_W?usp=share_link'
    }
  },
  home: {
    version: HomeVersion.SIMPLE_VOICE_MODEL,
    data: {
      youtubeVideo: 'https://www.youtube.com/embed/fLIu4laGM2M',
      title: 'Video de boas vindas Patrick'
    }
  }
};
