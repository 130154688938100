import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 100vw;
  margin: 0 auto;
  height: calc(100vh - 5rem);

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LoginForm = styled.form`
  h2 {
    font-size: 2.2rem;
    line-height: 2.2rem;

    margin-bottom: 2rem;
  }

  h4 {
    font-size: 1.2rem;
    line-height: 1.2rem;

    margin-bottom: 2rem;
  }

  input {
    display: block;
    width: calc(100% - 2.4rem);
    height: 4rem;
    background: var(--zs-white);

    border: 1px solid;
    border-color: var(--zs-gray-100);
    border-radius: 0.5rem;

    color: var(--text-input);
    font-size: 1rem;
    font-weight: 500;
    padding: 0 1.2rem;

    & + input {
      margin-top: 1rem;
    }

    & + button {
      margin-top: 2rem;
    }
  }

  span {
    display: block;
    margin-top: 1rem;
    color: var(--zs-gray-400);
    font-size: 0.8rem;
    cursor: pointer;
  }

  a {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .secondMessage {
    margin-top: 5rem;
  }
`;

export const ContentContainer = styled.main`
  width: 40vw;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 70vw;
  }


  a {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  h2 {
    font-size: 2.2rem;
    line-height: 2.2rem;

    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  h4 {
    font-size: 1.2rem;
    line-height: 1.2rem;

    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  footer {
    position: absolute;
    bottom: -2rem;
    width: 30vw;

    div {
      display: flex;
      justify-content: space-between;


      span {
        display: block;

        a {
          font-weight: 500;
          color: var(--zs-primary-500);
          text-decoration: underline;
        }

        &.loveMessage {
          color: var(--zs-gray-400);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .backgroundContainer, .branding {
    display: none;
  }


  .container {
    max-width: 400px;
  }

  .contentContainer {
    width: 100%;
    max-width: 400px;

    footer {
      position: absolute;
      left: 0;
      bottom: -2rem;
      width: 100%;
    }
  }
`;
