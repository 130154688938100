import { useForm } from 'react-hook-form';
import { Icons, Button } from '@zapfunnel/components';
import { Api } from '../../hooks/api';
import toast from 'react-hot-toast';
import { Container, ContentContainer, LoginForm } from './RecoveryAccess.style';
import { useNavigate } from 'react-router-dom';

export function HomeAccess() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm<{ email: string }>();
  const navigate = useNavigate();

  async function onRequestAccess({ email }: { email: string }) {
    const response = await Api.post<{
      email: string;
      access: {
        key: string;
        status: string;
        expirationDate: string;
        paidAt: string;
        tenant: {
          id: string;
          name: string;
          extensionId: string;
          logo: string;
        };
      }[];
    }>('/access/recovery', {
      email,
      tenantId: process.env.REACT_APP_TENANT_ID
    });

    if (!('email' in response && 'access' in response && response.access.length > 0)) {
      toast.error(`Não encontramos uma venda neste email!`);
      return;
    }

    localStorage.setItem('@funils:access', JSON.stringify(response.access));

    if (response.access.length === 1) {
      const access = response.access[0];
      navigate('/access/verify-extension/' + access.key);
      return;
    }

    if (response.access.length > 1) {
      navigate('/access/choose-extension');
      return;
    }
  }

  return (
    <>
      <Container>
        <ContentContainer>
          <LoginForm onSubmit={handleSubmit(onRequestAccess)}>
            <h4>
              Preencha seu e-mail abaixo em &quot;continuar&quot; para iniciar processo de ativação
              do acesso!
            </h4>
            <input type="text" placeholder="Email" {...register('email', { required: true })} />

            <Button.Submit isLoading={isSubmitting} full={true} theme="dark">
              Continuar
            </Button.Submit>
          </LoginForm>
          <footer>
            <div>
              <span className="loveMessage">
                <Icons.Love />
              </span>
            </div>
          </footer>
        </ContentContainer>
      </Container>
    </>
  );
}
